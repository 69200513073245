import ApiService from "./api.service";

const SubscriptionsService = {
  async getInvoiceDetails() {
    return await ApiService.commonGETRequest(`/subscription/invoice/details/`);
  },
  async getCard() {
    return await ApiService.commonGETRequest(`/subscription/card/`);
  },
  async addCard(data) {
    return await ApiService.commonPOSTRequest(`/subscription/card/`, data);
  },
  async removeCard() {
    return await ApiService.commonDELETERequest(`/subscription/card/`);
  },
  async changeCard(data) {
    return await ApiService.commonPUTRequest(`/subscription/card/`, data);
  },
  async getSubscriptionsDetails() {
    return await ApiService.commonGETRequest(`/subscription/`)
  },
  async cancelSubscription() {
    return await ApiService.commonDELETERequest(`/subscription/`);
  },
  async pay() {
    return await ApiService.commonPOSTRequest(`/subscription/pay/`);
  }
};

export default SubscriptionsService;

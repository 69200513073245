const CARD_STATUS = {
    INACTIVE: 0,
    ACTIVE: 1,
    PENDING: 2
}

const SUBSCRIPTION_STATUS = {
    ACTIVE: 'A', 
    PAID: 'P', 
    SUSPENDED: 'S', 
    BLOCKED: 'B', 
    DISABLED: 'D',
    PENDING_CANCELLATION: 'E',
    OVERDUE_BLOCKED: 'O',
    CHURNED: 'C'
}

export {
    CARD_STATUS,
    SUBSCRIPTION_STATUS
}